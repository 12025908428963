import React from "react"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"

const Banner = ({ data }) => {
  return (
    <section>
      <div className="banner animate__animated">
        <GatsbyImage
          image={data.image.childImageSharp.gatsbyImageData}
          alt={data.heading}
          className="bannerImage"
        />
        <div className="bannerOverlay"></div>
        <div className="container">
          <ReactMarkdown
            children={data.heading}
            className="text-white animate__animated animate__fadeInLeft"
          ></ReactMarkdown>
          <ReactMarkdown
            children={data.description}
            className="text-light animate__animated animate__fadeInLeftBig d-none d-lg-block"
          ></ReactMarkdown>
        </div>
      </div>
    </section>
  )
}

export default Banner
